body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.footer {
  margin: '5em 0em 0em' !important;
  padding: '5em 0em' !important;
}

html,
body,
#root {
  /* we don't want any of these to scroll */
  overflow: hidden;
  height: 100%;
  width: 100%;
}

.ui.segments {
  border: unset !important;
}

.ui.segment {
  border: unset !important;
}
